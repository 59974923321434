import React from "react";

import Layout, { LayoutData } from "../components/layout";
import SEO from "../components/seo";
import { graphql } from "gatsby";
import { Article } from "../components/article";
import { PageSection } from "../components/page-section";

const MapPage = (props: Props) => {
  const { html, frontmatter: { title } } = props.data.mapPageData
  return (
    <Layout {...props}>
      <SEO
        title="Trasa"
      />
      <PageSection>
        <Article html={html} title={title} />
      </PageSection>
    </Layout>
  );
}

interface Props {
  data: {
    mapPageData: {
      frontmatter: {
        title: string
      }
      html: string
    }
  } & LayoutData
}

export const pageQuery = graphql`
  query MapPageQuery {
    mapPageData: markdownRemark(frontmatter: {contentId: {eq: "map"}}) {
          id
          frontmatter {
            title
          }
          html
        }
    ...LayoutFragment
  }`


export default MapPage;
